import React from 'react';
import './App.css';
import BackgroundImage from '../components/BackgroundImage/BackgroundImage';
import IconXSVG from '../components/icons/TwitterSVG/IconXSVG';
import IconSteamSVG from '../components/icons/TwitterSVG/IconSteam';
import useWindowWidth from '../hooks/useWindowWidth';

const ComingSoonText = () => {
  return (
    <div className='coming-soon-text'>
      <span style={{ margin: 'auto' }}>Coming Soon!</span>
    </div>)
}

const TwitterLink = () => {
  const windowWidth = useWindowWidth();
  return (<a href='https://twitter.com/proton_vr' style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" className='socials-hovered'>
    <div style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
      <div className='socials-glow'>
        <IconXSVG size={windowWidth > 768 ? "4vw" : "8vw"} />
      </div>
      <span className='socials-text'>Twitter</span>
    </div>
  </a>);
}

const SteamLink = () => {

  const windowWidth = useWindowWidth();

  return (
    <div style={{ display: 'flex', gap: 8, justifyContent: 'center', alignItems: 'center' }}>
      <div className='socials-glow'>
        <IconSteamSVG size={windowWidth > 768 ? "4vw" : "8vw"} />
      </div>
      <span className='socials-text'>{windowWidth > 768 ? "Steam Page (coming soon)" : "Steam Page\n(Soon)"}</span>
    </div>
  );
}

const App = () => {
  return (
    <BackgroundImage imageUrl="/images/centurion-army-background-image.png" >
      <div className='main-container'>
        <div className='coming-soon-container'>
          <img src='/images/Banner.png' alt='banner' />
          <ComingSoonText />
        </div>
        <div className='socials-container'>
          <TwitterLink />
          <SteamLink />
        </div>
      </div>
    </BackgroundImage>
  )
}

export default App;
