import React from 'react';

interface BackgroundImageProps {
  imageUrl: string;
  children?: React.ReactNode;
}


const backgroundStyle = (imageUrl: string) => ({
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
});

const BackgroundImage: React.FC<BackgroundImageProps> = ({ imageUrl, children }) => {

  return (
    <div style={{
      ...backgroundStyle(imageUrl),
      position: 'fixed',
      height: '100%',
      width: '100%'
    }}>
      {children}
    </div>
  );
}

export default BackgroundImage;