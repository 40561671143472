import React from "react";

interface size {
    size?: number | string;
}

const IconXSVG: React.FC<size> = ({ size = 256 }) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 150 150">
                <defs>
                    <filter id="green-glow" height="300%" width="300%" x="-75%" y="-75%">
                        <feMorphology operator="dilate" radius="2" in="SourceAlpha" result="thicken" />
                        <feGaussianBlur in="thicken" stdDeviation="10" result="blurred" />
                        <feFlood flood-color="#00ff84af" result="glowColor" />
                        <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />
                        <feMerge>
                            <feMergeNode in="softGlow_colored" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter id="green-glow-strong" height="300%" width="300%" x="-75%" y="-75%">
                        <feMorphology operator="dilate" radius="3" in="SourceAlpha" result="thicken" />
                        <feGaussianBlur in="thicken" stdDeviation="10" result="blurred" />
                        <feFlood flood-color="#00ff84af" result="glowColor" />
                        <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />
                        <feMerge>
                            <feMergeNode in="softGlow_colored" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <path d="M67 .55C40.648 3.3 17.102 20.649 6.398 45.2-.5 61.05-1.648 79.3 3.25 96.101c6.05 20.949 21.7 38.648 41.95 47.5 15.85 6.898 34.1 8.046 50.902 3.148 25.148-7.25 44.949-27.898 51.449-53.55C157.699 53 132.8 11.897 92.5 2.3 83.648.2 75.852-.351 67 .55ZM68 47c6.398 8.5 11.8 15.5 12 15.5.2 0 6.852-7 14.7-15.5l14.35-15.5h8.15l-1.75 1.852c-1 1.046-7.5 8.097-14.45 15.648-6.95 7.55-13.75 14.898-15.102 16.25L83.5 67.8l18.75 24.95c10.3 13.75 18.75 25.148 18.75 25.352 0 .25-6.148.398-13.602.398l-13.648-.05-12.7-17C72.3 89.7 68.2 84.647 67.8 84.95c-.3.25-7.402 7.902-15.8 17L36.75 118.5h-3.852c-2.148 0-3.898-.102-3.898-.25 0-.148 7.95-8.852 17.648-19.3 9.653-10.45 17.653-19.2 17.75-19.348C64.45 79.398 56.5 68.55 46.75 55.5 37 42.5 29 31.75 29 31.648c0-.097 6.148-.148 13.7-.148h13.698Zm0 0" style={{ stroke: 'none', fillRule: 'nonzero', fill: '#00FF85', fillOpacity: '1' }} />
                <path d="M41.352 38.2c.199.35 12.796 17.25 27.898 37.448l27.5 36.801 6.148.051c3.801 0 6.102-.2 6-.5-.046-.25-12.597-17.148-27.796-37.5l-27.704-37H47.2c-5.148 0-6.199.102-5.847.7Zm0 0" style={{ stroke: 'none', fillRule: 'nonzero', fill: '#00FF85', fillOpacity: '1' }} />
            </svg>
        </div>
    );
}

export default IconXSVG;